<template>
  <my-dv-box class="body" xAlign="center" top="128px" :position="true"  width="100%" height="calc(100% - 111px)" >
    <ws-title left="29px" top="-37px" title="总氮变化趋势（mg/l）"></ws-title>
    <oxy v-if="ocVo" :ocVo="ocVo"></oxy>
    <ws-title left="29px" top="210px"  title="pH变化趋势"></ws-title>
    <ph v-if="phVo" :phVo="phVo"></ph>
    <ws-title left="29px" top="448px"  title="总磷变化趋势（mg/l）"></ws-title>
    <wenter v-if="waterTVo" :waterTVo="waterTVo"></wenter>
    <ws-title left="29px" top="677px"  title="悬浮物变化趋势（mg/l）"></ws-title>
    <andan v-if="anVo" :anVo="anVo"></andan>
    <ws-title left="444px" top="687px"  title="高锰酸盐变化趋势（mg/l）"></ws-title>
    <orp v-if="orpVo" :orpVo="orpVo"></orp>
    <ahistory v-if="uploadTodayInfo" :uploadTodayInfo="uploadTodayInfo"></ahistory>
    <showmessage :numberinfo="numberinfo"></showmessage>
  </my-dv-box>
</template>

<script>
import MyDvBox from "@xdh/my/ui/lib/dv/packages/my-dv-box";
import Oxy from "./oxy";
import Ph from "./ph";
import Wenter from "./wenter";
import Andan from "./andan";
import Orp from "./orp";
import Ahistory from "./ahistory";
import Showmessage from "./showmessage";
import WsTitle from "@/views/component/ws/ws-title2";
import {regionchart, regioncountMessage, tailInformation} from "@/utils/api";
import mqtt from "mqtt";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  data(){
    return {
      anVo:null,
      phVo:null,
      orpVo:null,
      ocVo:null,
      waterTVo:null,
      numberinfo:{
        overproofToday:0,
        uploadAll:0,
        uploadToday:0,
      },
      uploadTodayInfo:null,
      timer:null,
    }
  },
  mounted() {
    this.homePage()
    this.waterinfo()
    this.regioncountMessage()
    this.timer=setInterval(this.regioncountMessage,1000*30)
  },
  beforeDestroy() {
    if (this.timer){
      clearInterval(this.timer)
      this.timer=null
    }
    if (this.client){
      this.client.end()
      this.client.close()
      this.client = null;
    }
  },
  methods:{
    regioncountMessage(){
      regioncountMessage(0).then(res=>{
        this.numberinfo={
          overproofToday:res.data.dataCleansing,
          uploadAll:res.data.totalUploads,
          uploadToday:res.data.uploadDataToday,
        }
      })
    },
    mqttMSG() {
      const options = {
        connectTimeout: 40000,
        clientId: 'mqtitId-Home',
        username: "",
        password: "",
        currentdata:null,
        reconnectPeriod: 1000 * 60 * 5, // 1000毫秒，两次重新连接之间的间隔
        // connectTimeout: 30 * 1000, // 1000毫秒，两次重新连接之间的间隔
        // resubscribe: true // 如果连接断开并重新连接，则会再次自动订阅已订阅的主题（默认true）
        clean: true
      }
      this.client = mqtt.connect(process.env.VUE_APP_BASE_SOKET + '/mqtt', options)
      if (this.client.connected) {
        this.client.end();
      }
      this.client.on("message", (topic, message) => {
        if('/xinbei/count'===topic){
          this.currentdata= JSON.parse(message.toString())
          this.numberinfo={
            overproofToday:this.currentdata.dataCleansing,
            uploadAll:this.currentdata.totalUploads,
            uploadToday:this.currentdata.totalUploads,
          }
        }
      })
      this.client.on("connect", e => {
        this.client.subscribe(
            ['/xinbei/count'],
            error => {
              if (!error) {
                console.log("订阅成功",error);
              } else {
                console.log("订阅失败");
              }
            });
      })
    },
    waterinfo(){
      tailInformation().then((res) => {
        this.uploadTodayInfo=res.data
      })
      // this.$http.get('/zhonglou/ks/water/info').then(res=>{
      //   this.uploadTodayInfo=res.data.uploadTodayInfo
      //   this.numberinfo={
      //     overproofToday:res.data.overproofToday,
      //     uploadAll:res.data.uploadAll,
      //     uploadToday:res.data.uploadToday,
      //   }
      // })
    },
    homePage(){
      regionchart().then(res=>{
        this.phVo=res.data.ph
        this.orpVo=res.data.kmn
        this.anVo=res.data.suspendedMatter
        this.waterTVo=res.data.p
        this.ocVo=res.data.n
      })
      // this.$http.get('/zhonglou/ks/waterTrend').then(res=>{
      //   this.orpVo=res.data.orpVo
      //   this.anVo=res.data.anVo
      //   this.ocVo=res.data.ocVo
      //   this.waterTVo=res.data.waterTVo
      // })
    },
  },
  components:{WsTitle, Showmessage, Ahistory, Orp, Andan, Wenter, Ph, Oxy, MyDvBox,},
};
</script>

<style scoped>

</style>
