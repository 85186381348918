<template>
  <my-dv-box class="mianji" left="850px" top="687px"
             width="1031px" height="208px">
      <div class="itemds ih"   >
        <div class="o-h-1 bgc1 flex-center">
<!--          <my-dv-text width="202px" ellipsis :rows="1">-->
            蟹塘
<!--          </my-dv-text>-->
        </div>
        <div class="o-h-2 bgc1 flex-center">
<!--          <my-dv-text width="93px" ellipsis :rows="1">-->
            pH值
<!--          </my-dv-text>-->
        </div>
        <div class="o-h-3 bgc1 flex-center">
<!--          <my-dv-text width="141px" ellipsis :rows="1">-->
            总磷
<!--          </my-dv-text>-->
        </div>
        <div class="o-h-4 bgc1 flex-center">
<!--          <my-dv-text width="141px" ellipsis :rows="1">-->
            总氮
<!--          </my-dv-text>-->
        </div>
        <div class="o-h-5 bgc1 flex-center">
<!--          <my-dv-text width="141px" ellipsis :rows="1">-->
            悬浮物
<!--          </my-dv-text>-->
        </div>
        <div class="o-h-6 bgc1 flex-center">
<!--          <my-dv-text width=" 90px" ellipsis :rows="1">-->
            高锰酸钾
<!--          </my-dv-text>-->
        </div>
        <div class="o-h-7 bgc1 flex-center">
<!--          <my-dv-text width="222px"  ellipsis :rows="1">-->
            上传时间
<!--          </my-dv-text>-->
        </div>
      </div>
    <div style="overflow-y:hidden;width: 100%;height: 170px;">
      <my-marquee class="container"  :speed="0.5"  :data="list">
        <div class="itemds"  v-for="item in overproofTodayInfo">
          <div class="o-h-1 bgc1 flex-center">
            <!--          <my-dv-text width="202px" ellipsis :rows="1" >-->
            {{ item.name}}
            <!--          </my-dv-text>-->
          </div>
          <div class="o-h-2 bgc1 flex-center">
            <!--          <my-dv-text width="93px" ellipsis :rows="1">-->
            {{item.ph}}
            <!--          </my-dv-text>-->
          </div>
          <div class="o-h-3 bgc1 flex-center">
            <!--          <my-dv-text width="141px" ellipsis :rows="1">-->
            {{item.p}}
            <!--          </my-dv-text>-->
          </div>
          <div class="o-h-4 bgc1 flex-center">
            <!--          <my-dv-text width="141px" ellipsis :rows="1">-->
            {{item.n}}
            <!--          </my-dv-text>-->
          </div>
          <div class="o-h-5 bgc1 flex-center">
            <!--          <my-dv-text width="141px" ellipsis :rows="1">-->
            {{item.suspendedSolids}}
            <!--          </my-dv-text>-->
          </div>
          <div class="o-h-6 bgc1 flex-center">
            <!--          <my-dv-text width=" 90px" ellipsis :rows="1">-->
            {{item.k}}
            <!--          </my-dv-text>-->
          </div>
          <div class="o-h-7 bgc1 flex-center">
            <!--          <my-dv-text width="222px"  ellipsis :rows="1">-->
            <my-date format="YYYY-MM-DD HH:mm" :value="item.upLoadTime"></my-date>
            <!--          </my-dv-text>-->
          </div>
        </div>
      </my-marquee>
    </div>
    </my-dv-box>
</template>

<script>
import MyDvBox from "@xdh/my/ui/lib/dv/packages/my-dv-box";
import MyDvText from "@xdh/my/ui/lib/dv/packages/my-dv-text";
import PiMarquee from "@/views/component/pi-marquee";

export default {
  props:['uploadTodayInfo'],
  components:{PiMarquee, MyDvBox,MyDvText},
  watch:{
    uploadTodayInfo(){
      this.dolist()
    },
  },
  mounted() {
    this.dolist()
  },
  methods:{
    dolist(){
      let list=[]
      if (this.uploadTodayInfo){
        this.uploadTodayInfo.map(i=>{
          list.push(i)
        })
      }
      this.overproofTodayInfo=list
    },
  },
  data() {
    let t='啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊'
    return {
      overproofTodayInfo:[],
    }
  }
};
</script>

<style scoped lang="scss">
.mianji{
  //background: linear-gradient(0deg, rgba(4,45,100,0.25) 75%, rgba(255,255,255,0) 100%, rgba(21,34,54,0) 100%);
  //background-image: url("~@/assets/images/组 2491.png");
  //background-repeat: no-repeat;
  //background-size: 100% 100%;
  border-radius:0 0 20px 20px;
  .back{
    background: linear-gradient(180deg, rgba(4,45,100,0) 0%, rgba(4,45,100,0.25) 75%,  rgba(21,34,54,0.95) 100%);
  }
  .itemds{
    width: 1031px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 42px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    .o-h-1{
      width: 202px;

    }
    .o-h-2{
      width: 93px;
    }
    .o-h-3{
      width: 141px;
    }
    .o-h-4{
      width: 141px;
    }
    .o-h-5{
      width: 141px;
    }
    .o-h-6{
      width: 90px;
    }
    .o-h-7{
      width: 222px;
    }
    .bgc1{
      height: 100%;
      line-height: 42px;
      text-align: center;
      display: flex;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
    }
    .flex-center{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ih{
    height: 48px;
      background: linear-gradient(180deg, #3272BC 0%, #053062 100%);
  }

}
</style>
