<template>
  <my-dv-box class="mianji" left="29px" top="740px" width="391px" height="174px">
    <my-dv-box width="344px"  x-align="center" height="156px" left="0" top="7px">
      <my-dv-bar fit :columns="columns" :rows="rows" :extend="extend"></my-dv-bar>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import MyDvBox from "@xdh/my/ui/lib/dv/packages/my-dv-box";
import MyDvBar from "@xdh/my/ui/lib/dv/packages/my-dv-bar";

export default {
  props:['anVo'],
  components:{MyDvBox,MyDvBar},
  mounted() {
    this.dorwos()
  },
  watch:{
    anVo(){
      this.dorwos()
    },
  },
  methods:{
    dorwos(){
      let rows=[];
      if (this.anVo&&this.anVo.x){
        this.anVo.x.map((i,k)=>{
          rows.push([i, this.anVo.y[k]])
        })
      }
      this.rows=rows
    }
  },
  data() {
    return {
      columns: ['日期', '悬浮物'],
      rows: [],
      extend: {
        'series[0].barWidth':9,
        xAxis:{
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF'
          },
          axisTick:{
            show:false,
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          splitLine:{
            show:false,
            lineStyle:{
              color:'red'
            }
          },
        },
        yAxis: {
          axisTick:{
            show:false,
          },
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF'
          },
          splitArea: {
            show: false,
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          splitLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
        },
        color: [{
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0, color: 'rgba(51, 117, 192, 1)' // 0% 处的颜色
          }, {
            offset: 1, color: 'rgba(2, 16, 45, 1)' // 100% 处的颜色
          }],
          global: false // 缺省为 false
        }],
        grid: {
          left: 0,
          bottom: 0,
          top: 10,
          right: 0,
          containLabel: true,
        },
        legend: {
          top: 0,
          textStyle: {
            fontSize: 12,
          },
        },
      }
    }
  }
};
</script>

<style scoped lang="scss">
.mianji{

}
</style>
