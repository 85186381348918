<template>
  <my-dv-box class="ws-title" :width="width" :class="{more:ismore}" height="47px"
             :left="left" :top="top">

    <my-dv-box class="ws-title-right" width="335.7px"
               height="31px" y-align="middle" x-align="right">
      <my-dv-box class="titlename" y-align="middle" left="24px">{{title}}</my-dv-box>
    </my-dv-box>
    <my-dv-box v-if="ismore" top="18px" x-align="right" style="cursor: pointer" @click="triggleclick">
      更多>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
export default {
  name: "ws-title2",
  props:{
    height:{
      type:String,
      default:'',
    },
    ismore:{
      type:Boolean,
      default:false,
    },
    left:{
      type:String,
      default:'0',
    },
    top:{
      type:String,
      default:'0',
    },
    title:{
      type:String,
      require:true,
    },
    width:{
      type:String,
      default:'358px',
    },
  },
  methods:{
    triggleclick(){
      this.$emit('click')
    },
  },
};
</script>

<style scoped lang="scss">
.ws-title{
  background-image: url("~@/assets/images/hrewrew.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &.more{
    background-image: url("~@/assets/images/hrsadsad.png") ;
  }
  .titlename{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>
