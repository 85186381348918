import { render, staticRenderFns } from "./oxy.vue?vue&type=template&id=e9b25dcc&scoped=true&"
import script from "./oxy.vue?vue&type=script&lang=js&"
export * from "./oxy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9b25dcc",
  null
  
)

export default component.exports