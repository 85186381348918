
/**
 * 判断是否为对象或函数
 * @param {*} value 需要检测的值
 * @returns {boolean} 判断的结果，布尔值
 * @example
 * isObject(new Object()) // true
 * isOject(function foo() {}) // true
 * isObject(null) // false
 */
export function isObject(value) {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}


export default {
  isObject
}
