<template>
  <my-dv-box class="mianji" left="29px" top="497px" width="391px" height="174px">
    <my-dv-box width="355px"  x-align="center" height="155px" left="0" top="8px">
      <my-dv-line fit area :columns="columns"  :rows="rows" :extend="extend"></my-dv-line>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import MyDvBox from "@xdh/my/ui/lib/dv/packages/my-dv-box";
import MyDvLine from "@xdh/my/ui/lib/dv/packages/my-dv-line";

export default {
  props:['waterTVo'],
  components:{MyDvBox,MyDvLine},
  mounted() {
    this.dorwos()
  },
  watch:{
    waterTVo(){
      this.dorwos()
    },
  },
  methods:{
    dorwos(){
      let rows=[];
      if (this.waterTVo&&this.waterTVo.x){
        this.waterTVo.x.map((i,k)=>{
          rows.push([i, this.waterTVo.y[k]])
        })
      }
      this.rows=rows
    }
  },
  data() {
    return {
      columns: ['日期', '总磷'],
      rows: [],
      extend: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },
        'series[0].areaStyle.color':{
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0, color: 'rgba(1, 84, 191, 0)' // 0% 处的颜色
          }, {
            offset: 1, color: 'rgba(10, 92, 188, 1)' // 100% 处的颜色
          }],
          global: false // 缺省为 false
        },
        xAxis:{
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF'
          },
          axisTick:{
            show:false,
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          splitLine:{
            show:false,
            lineStyle:{
              color:'red'
            }
          },
        },
        yAxis: {
          axisTick:{
            show:false,
          },
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF'
          },
          splitArea: {
            show: false,
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          splitLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
        },
        color:'rgba(0, 216, 253, 1)',
        grid: {
          left: 0,
          bottom: 0,
          top: 10,
          right: 0,
          containLabel: true,
        },
        legend: {
          top: 0,
          textStyle: {
            fontSize: 12,
          },
        },
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>
