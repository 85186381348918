<template>
  <my-dv-box class="mianji" left="29px" top="259px" width="391px" height="174px">
    <my-dv-box width="351px"  x-align="center" height="156px" left="0" top="7px">
      <div ref="bar" style="width: 100%;height: 100%"></div>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import MyDvBox from "@xdh/my/ui/lib/dv/packages/my-dv-box";
import MyDvBar from "@xdh/my/ui/lib/dv/packages/my-dv-bar";
import * as echarts from "echarts";

export default {
  props:['phVo'],
  mounted() {
    this.$nextTick(_=>{
      this.initdata()
    })
  },
  watch:{
    phVo(){
      this.initdata()
    },
  },
  methods:{
    // dorwos(){
    //   let rows=[];
    //   if (this.phVo&&this.phVo.list){
    //     this.phVo.list.map(i=>{
    //       rows.push([i.time,i.value])
    //     })
    //   }
    //   this.rows=rows
    // },
    initdata(){
      let xData = this.phVo.x,
          yData =  this.phVo.y;
      // if (this.phVo&&this.phVo.list){
      //   this.phVo.list.map(function(a, b) {
      //     xData.push(a.time);
      //     yData.push(a.value);
      //   });
      // }

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              opacity: 0
            }
          },
          formatter: function(prams) {
            return prams[0].seriesName+"：" + prams[0].data
          }
        },
        legend: {
          data: ['直接访问', 'pH值'],
          show: false
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0',
          top: '7%',
          containLabel: true,
          z: 22
        },
        xAxis: [{
          type: 'category',
          gridIndex: 0,
          data: xData,
          axisTick: {
            alignWithLabel: true
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          axisLabel:{
            fontSize: 13,
            color:'#FFFFFF'
          },
        }],
        yAxis: [{
          type: 'value',
          gridIndex: 0,
          splitLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          axisTick: {
            show: false
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF'
          },
        },
          {
            type: 'value',
            gridIndex: 0,
            splitNumber: 12,
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: true
            },
            axisLabel: {
              show: false
            },
            splitArea: {
              show: false,
              areaStyle: {
                color: ['rgba(250,250,250,0.0)', 'rgba(250,250,250,0.05)']
              }
            }
          }
        ],
        color: [{
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0, color: 'rgba(0, 240, 255, 1)' // 0% 处的颜色
          }, {
            offset: 1, color: 'rgba(14, 117, 117, 1)' // 100% 处的颜色
          }],
          global: false // 缺省为 false
        }],
        series: [    {
          // 值
          name: 'pH值',
          type: 'bar',
          barWidth: 14,
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(52, 117, 193, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(53, 118, 191, 0)',
                },
              ]),
            },
          },
          data: yData,

          label: {
            show: false,
            position: 'top',
            distance: 10,
            fontSize: 16,
            fontFamily:'DIN',
            color: '#ffffff',
            zlevel: 4,
            z: 10,
            formatter:'{c}%'
          },
        },
          {
            type: "scatter",
            symbol:'image://'+require('@/assets/images/gfujytyer.png'),
            symbolSize: 21,
            silent: true,
            z: 10,
            zlevel: 2,
            data: yData,
          }]
      };
      this.mapChart = echarts.init(this.$refs.bar);
      this.mapChart.setOption(option)
    },
  },
  components:{MyDvBox},
  data() {
    return {
      columns: ['日期', 'pH值'],
      rows: [ ],
    }
  }
};
</script>

<style scoped lang="scss">

</style>
